import { PageWithIntro, WizardLayout } from 'components';
import { MainPage } from './_MainPage';
import React from 'react';
import { plantInventoryStep } from '../../../utilities/stepDefinitions';
import { useTracking } from '../../../utilities/hooks';

const PlantInventoryPage = props => {
  useTracking('PlantInventory');

  return (
    <PageWithIntro step={plantInventoryStep}>
      <WizardLayout text>
        <MainPage {...props} />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default PlantInventoryPage;
