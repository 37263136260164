import {
  BulkFlowerGroupForm,
  CloneGroupForm,
  SeedGroupForm,
  ShakeTrimGroupForm,
} from 'components/Forms/PlantInventoryForms';
import React, { Fragment } from 'react';
import {
  newBulkFlowerGroup,
  newCloneGroup,
  newSeedGroup,
  newShakeTrimGroup,
} from '../../../utilities/plantInventory';

import { Grid } from 'semantic-ui-react';
import ItemsList from 'components/widgets/ItemsList';

export const LicenseDetailsView = props => {
  const {
    license,
    seedGroups,
    cloneGroups,
    bulkFlowerGroups,
    shakeTrimGroups,
    groupTitleTemplate,
    skippableSectionsBuilders,
  } = props;

  const section = ({
    Form,
    title,
    descriptionTemplate,
    submitText,
    cancelText,
    saveHandler,
    showCancel,
    editHandler,
    items,
    newItem,
  }) => (
    <Fragment>
      <h3>Understanding {title} in Traceability</h3>
      {descriptionTemplate}

      <h2>
        {title} for License # {license}
      </h2>

      <Grid columns={2}>
        <Grid.Column width={11}>
          <Form
            {...props}
            submitText={submitText}
            cancelText={cancelText}
            onSubmit={saveHandler}
            showCancel={showCancel}
            item={newItem(license)}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <ItemsList
            title={`${title} added to license`}
            titleTemplate={groupTitleTemplate}
            items={items}
            onEdit={editHandler}
          />
        </Grid.Column>
      </Grid>
    </Fragment>
  );

  const seedGroupsDescription = (
    <>
      <p>
        Now that we understand your operation and where your growing plant are on your
        farm, it is time to get your Seeds, Clones, Bulk Flower and Shake/Trim inventory
        accounted for.
      </p>

      <p>
        We will start this process with seeds. To simplify this, we will ask you some
        questions that help ‘group’ your seeds. Each group of seeds will live in the same
        room, should have the same Strain Name and the same birthday, along with some
        other details we will ask you.
      </p>

      <p>
        Keep adding seed groups until all of the seeds at your operation are accounted
        for.
      </p>
    </>
  );

  const seedGroupsSection = skippableSectionsBuilders.seedGroups(
    section({
      title: 'Seed Groups',
      Form: SeedGroupForm,
      editHandler: props.onEditSeedGroup,
      saveHandler: props.onSaveSeedGroup,
      descriptionTemplate: seedGroupsDescription,
      submitText: '+ Submit Seed Group',
      items: seedGroups,
      newItem: newSeedGroup,
    })
  );

  const cloneGroupsDescription = (
    <>
      <p>
        Next up, your Clone Groups. Clones are all of the plants that have not yet fully
        rooted, and are usually in ‘clone domes’ or something similar.
      </p>

      <p>
        To get your clone groups into GrowFlow, complete the form below for each ‘group’
        of clones. Each group will be the same strain, have the same ‘mother’ plant, same
        date of birth and be in the same room.
      </p>

      <p>
        Keep adding all of your clone groups until all of the clones at your operation are
        accounted for.
      </p>
    </>
  );

  const cloneGroupsSection = skippableSectionsBuilders.cloneGroups(
    section({
      Form: CloneGroupForm,
      editHandler: props.onEditCloneGroup,
      saveHandler: props.onSaveCloneGroup,
      title: 'Clone Groups',
      descriptionTemplate: cloneGroupsDescription,
      submitText: '+ Submit Clone Group',
      items: cloneGroups,
      newItem: newCloneGroup,
    })
  );

  const bulkFlowerGroupsDescription = (
    <>
      <p>
        The next plant inventory type is your harvested Flower. These are the
        quinessential ‘buds’ or premium harvested flower, not including your shake/trim
        weight (we will cover that in the next section).
      </p>

      <p>
        Each harvested group of bulk flower should have the same strain name, same date of
        birth and be in the same inventory room. For quantity, enter in the weight of the
        the group of bulk flower, in grams.
      </p>

      <p>
        Keep adding all of your bulk flower groups until all of the bulk flower at your
        operation is accounted for.{' '}
      </p>
    </>
  );

  const bulkFlowerGroupsSection = skippableSectionsBuilders.bulkFlowerGroups(
    section({
      Form: BulkFlowerGroupForm,
      editHandler: props.onEditBulkFlowerGroup,
      saveHandler: props.onSaveBulkFlowerGroup,
      title: 'Bulk Flower Groups',
      descriptionTemplate: bulkFlowerGroupsDescription,
      submitText: '+ Submit Bulk Flower Group',
      items: bulkFlowerGroups,
      newItem: newBulkFlowerGroup,
    })
  );

  const shakeTrimGroupsDescription = (
    <>
      <p>
        The last plant inventory type is your harvested Shake/Trim. This is all of the
        material you trimmed off during harvest, that you saved in order to be made into a
        sellable product, but that is not actually ‘bud’ or premium flower.
      </p>

      <p>
        Each harvested group of Shake/Trim should have the same strain name, same date of
        birth and be in the same inventory room. For quantity, enter in the weight of the
        the Shake/Trim group, in grams.
      </p>

      <p>
        Keep adding all of your Shake/Trim groups until all of the Shake/Trim at your
        operation is accounted for.{' '}
      </p>
    </>
  );

  const shakeTrimGroupsSection = skippableSectionsBuilders.shakeTrimGroups(
    section({
      Form: ShakeTrimGroupForm,
      editHandler: props.onEditShakeTrimGroup,
      saveHandler: props.onSaveShakeTrimGroup,
      title: 'Shake/Trim Groups',
      descriptionTemplate: shakeTrimGroupsDescription,
      submitText: '+ Submit Shake/Trim Group',
      items: shakeTrimGroups,
      newItem: newShakeTrimGroup,
    })
  );

  return (
    <Fragment>
      {seedGroupsSection}

      <br />

      {cloneGroupsSection}

      <br />

      {bulkFlowerGroupsSection}

      <br />

      {shakeTrimGroupsSection}
    </Fragment>
  );
};
