import { Button, Divider, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';

import ItemsList from 'components/widgets/ItemsList';
import NextStepButton from 'components/widgets/NextStepButton';

const LicenseSection = ({
  groupTitleTemplate,
  license,
  seedGroups,
  cloneGroups,
  bulkFlowerGroups,
  shakeTrimGroups,
  onEditSeedGroup,
  onEditCloneGroup,
  onEditBulkFlowerGroup,
  onEditShakeTrimGroup,
  onAddSeedGroup,
  onAddCloneGroup,
  onAddBulkFlowerGroup,
  onAddShakeTrimGroup,
  skippableSectionsBuilders,
}) => (
  <Fragment>
    <h2>Plant Inventory for License #{license}</h2>

    <Grid columns="2">
      <Grid.Row>
        <Grid.Column width={8}>
          {skippableSectionsBuilders.seedGroups(
            <Fragment>
              <ItemsList
                title="Seed Inventory"
                titleTemplate={groupTitleTemplate}
                items={seedGroups}
                onEdit={onEditSeedGroup}
              />
              <br />

              <Button onClick={() => onAddSeedGroup(license)}>+ Add Seed Group</Button>
            </Fragment>
          )}
        </Grid.Column>

        <Grid.Column width={8}>
          {skippableSectionsBuilders.cloneGroups(
            <Fragment>
              <ItemsList
                title="Clone Inventory"
                titleTemplate={groupTitleTemplate}
                items={cloneGroups}
                onEdit={onEditCloneGroup}
              />

              <br />

              <Button onClick={() => onAddCloneGroup(license)}>+ Add Clone Group</Button>
            </Fragment>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          {skippableSectionsBuilders.bulkFlowerGroups(
            <Fragment>
              <ItemsList
                title="Bulk Flower Inventory"
                titleTemplate={groupTitleTemplate}
                items={bulkFlowerGroups}
                onEdit={onEditBulkFlowerGroup}
              />

              <br />

              <Button onClick={() => onAddBulkFlowerGroup(license)}>
                + Add Bulk Flower Group
              </Button>
            </Fragment>
          )}
        </Grid.Column>

        <Grid.Column width={8}>
          {skippableSectionsBuilders.shakeTrimGroups(
            <Fragment>
              <ItemsList
                title="Shake/Trim Inventory"
                titleTemplate={groupTitleTemplate}
                items={shakeTrimGroups}
                onEdit={onEditShakeTrimGroup}
              />

              <br />

              <Button onClick={() => onAddShakeTrimGroup(license)}>
                + Add Shake/Trim Group
              </Button>
            </Fragment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
  </Fragment>
);

export const ListingView = ({
  groupTitleTemplate,
  licenses,
  seedGroupsForLicense,
  cloneGroupsForLicense,
  bulkFlowerGroupsForLicense,
  shakeTrimGroupsForLicense,
  onEditSeedGroup,
  onEditCloneGroup,
  onEditBulkFlowerGroup,
  onEditShakeTrimGroup,
  onAddSeedGroup,
  onAddCloneGroup,
  onAddBulkFlowerGroup,
  onAddShakeTrimGroup,
  nextStep,
  stepCompleted,
  skippableSectionsBuilders,
}) => (
  <Fragment>
    {licenses.map(license => (
      <LicenseSection
        groupTitleTemplate={groupTitleTemplate}
        license={license}
        seedGroups={seedGroupsForLicense(license)}
        cloneGroups={cloneGroupsForLicense(license)}
        bulkFlowerGroups={bulkFlowerGroupsForLicense(license)}
        shakeTrimGroups={shakeTrimGroupsForLicense(license)}
        onEditSeedGroup={onEditSeedGroup}
        onEditCloneGroup={onEditCloneGroup}
        onEditBulkFlowerGroup={onEditBulkFlowerGroup}
        onEditShakeTrimGroup={onEditShakeTrimGroup}
        onAddSeedGroup={onAddSeedGroup}
        onAddCloneGroup={onAddCloneGroup}
        onAddBulkFlowerGroup={onAddBulkFlowerGroup}
        onAddShakeTrimGroup={onAddShakeTrimGroup}
        skippableSectionsBuilders={skippableSectionsBuilders(license)}
      />
    ))}

    <NextStepButton stepCompleted={stepCompleted} nextStep={nextStep} />
  </Fragment>
);
