import {
  ModalAddBulkFlowerGroupForm,
  ModalAddCloneGroupForm,
  ModalAddSeedGroupForm,
  ModalAddShakeTrimGroupForm,
  ModalEditBulkFlowerGroupForm,
  ModalEditCloneGroupForm,
  ModalEditSeedGroupForm,
  ModalEditShakeTrimGroupForm,
} from 'components/Forms/PlantInventoryForms';
import React, { useState } from 'react';
import {
  allState as allPlantInventoryState,
  createQueriesHook as createPlantInventoryQueries,
  createSaveBulkFlowerGroupHandler,
  createSaveCloneGroupHandler,
  createSaveSeedGroupHandler,
  createSaveShakeTrimGroupHandler,
  newBulkFlowerGroup,
  newCloneGroup,
  newSeedGroup,
  newShakeTrimGroup,
} from '../../../utilities/plantInventory';

import {
  allState as allRoomsState,
  createQueriesHook as createRoomQueries,
} from '../../../utilities/rooms';

import {
  allState as allSkipState,
  createSkippableSectionBuilderForKey,
  skipBulkFlowerGroupKeyBuilder,
  skipCloneGroupKeyBuilder,
  skipSeedGroupKeyBuilder,
  skipShakeTrimGroupKeyBuilder,
} from '../../../utilities/skippedEntries';

import {
  allState as allStrainState,
  createQueriesHook as createStrainQueries,
} from '../../../utilities/strains';

import { InternalStepProgress } from 'components';
import { LicenseWizardView } from './_LicenseWizardView';
import { ListingView } from './_ListingView';
import { WistiaVideo } from 'components/widgets/WistiaVideo';
import YesNoModal from 'components/widgets/YesNoModal';
import { createQueriesHook as createAllQueries } from '../../../utilities/allQueries';
import { deleteFormElement } from '../../../utilities/formstate';
import { getLicenses } from '../../../utilities/licenses';
import { plantInventoryStep } from '../../../utilities/stepDefinitions';
import { stepIsComplete } from '../../../utilities/steps';
import { validId } from '../../../utilities/matchers';

export const MainPage = props => {
  const plantInventoryState = allPlantInventoryState();
  const roomsState = allRoomsState();
  const strainState = allStrainState();
  const { skipState } = allSkipState();
  const allQueries = createAllQueries();

  const { licenses } = getLicenses();
  const noOp = () => {};

  const roomQueries = createRoomQueries(roomsState);
  const plantInventoryQueries = createPlantInventoryQueries(plantInventoryState);
  const strainQueries = createStrainQueries(strainState);
  const stepCompleted = stepIsComplete(plantInventoryStep);

  const groupTitleTemplate = item =>
    `${strainQueries.strainDetails[item.strainId].name}, ${item.dateOfBirth}`;

  const [groupIdBeingEdited, setGroupIdBeingEdited] = useState('');
  const [showGroupDeleteModal, setShowGroupDeleteModal] = useState(false);
  const [showGroupAddModal, setShowGroupAddModal] = useState(false);
  const [groupConfig, setGroupConfig] = useState('');
  const [licenseToAddTo, setLicenseToAddTo] = useState('');

  const nulloEditor = {
    editForm: () => null,
    addForm: () => null,
    saveHandler: noOp,
    editHandler: noOp,
    newItem: () => {},
  };

  const skippableSectionsBuilders = license => ({
    seedGroups: createSkippableSectionBuilderForKey(
      {
        label: 'I have no seed groups',
        keyBuilder: skipSeedGroupKeyBuilder,
        skipState,
        canSkip: allQueries.canSkipSeedGroupsForLicense(license),
      },
      license
    ),

    cloneGroups: createSkippableSectionBuilderForKey(
      {
        label: 'I have no clone groups',
        keyBuilder: skipCloneGroupKeyBuilder,
        skipState,
        canSkip: allQueries.canSkipCloneGroupsForLicense(license),
      },
      license
    ),

    bulkFlowerGroups: createSkippableSectionBuilderForKey(
      {
        label: 'I have no bulk flower groups',
        keyBuilder: skipBulkFlowerGroupKeyBuilder,
        skipState,
        canSkip: allQueries.canSkipBulkFlowerGroupsForLicense(license),
      },
      license
    ),

    shakeTrimGroups: createSkippableSectionBuilderForKey(
      {
        label: 'I have no shake/trim groups',
        keyBuilder: skipShakeTrimGroupKeyBuilder,
        skipState,
        canSkip: allQueries.canSkipShakeTrimGroupsForLicense(license),
      },
      license
    ),
  });

  const hideDeletionModal = () => {
    setShowGroupDeleteModal(false);
  };

  const hideAddModal = () => {
    setShowGroupAddModal(false);
  };

  const onModalClose = () => {
    setGroupIdBeingEdited('');
    hideDeletionModal();
    hideAddModal();
    setLicenseToAddTo('');
  };

  const createModalSaveHandler = builder =>
    builder({
      ...plantInventoryState,
      completion: onModalClose,
    });

  const createNonModalSaveHandler = builder =>
    builder({
      ...plantInventoryState,
    });

  const createDelete = key => () => {
    deleteFormElement(plantInventoryState[key], groupIdBeingEdited);
    onModalClose();
  };

  const editors = {
    seedGroup: {
      addForm: ModalAddSeedGroupForm,
      editForm: ModalEditSeedGroupForm,
      saveHandler: createModalSaveHandler(createSaveSeedGroupHandler),
      deleteHandler: createDelete('seedGroupsState'),
      newItem: newSeedGroup,
    },

    cloneGroup: {
      addForm: ModalAddCloneGroupForm,
      editForm: ModalEditCloneGroupForm,
      saveHandler: createModalSaveHandler(createSaveCloneGroupHandler),
      deleteHandler: createDelete('cloneGroupsState'),
      newItem: newCloneGroup,
    },

    bulkFlowerGroup: {
      addForm: ModalAddBulkFlowerGroupForm,
      editForm: ModalEditBulkFlowerGroupForm,
      saveHandler: createModalSaveHandler(createSaveBulkFlowerGroupHandler),
      deleteHandler: createDelete('bulkFlowerGroupsState'),
      newItem: newBulkFlowerGroup,
    },

    shakeTrimGroup: {
      addForm: ModalAddShakeTrimGroupForm,
      editForm: ModalEditShakeTrimGroupForm,
      saveHandler: createModalSaveHandler(createSaveShakeTrimGroupHandler),
      deleteHandler: createDelete('shakeTrimGroupsState'),
      newItem: newShakeTrimGroup,
    },
  };

  const promptForGroupDeletion = () => {
    setShowGroupDeleteModal(true);
  };

  const createAddInitiator = configKey => license => {
    setGroupConfig(configKey);
    setLicenseToAddTo(license);
    setShowGroupAddModal(true);
  };

  const createEditInitiator = configKey => id => {
    setGroupConfig(configKey);
    setGroupIdBeingEdited(id);
    setLicenseToAddTo(plantInventoryQueries.groupDetails[id].license);
  };

  const addSeedGroup = createAddInitiator('seedGroup');
  const addCloneGroup = createAddInitiator('cloneGroup');
  const addBulkFlowerGroup = createAddInitiator('bulkFlowerGroup');
  const addShakeTrimGroup = createAddInitiator('shakeTrimGroup');

  const editSeedGroup = createEditInitiator('seedGroup');
  const editCloneGroup = createEditInitiator('cloneGroup');
  const editBulkFlowerGroup = createEditInitiator('bulkFlowerGroup');
  const editShakeTrimGroup = createEditInitiator('shakeTrimGroup');

  const editor = editors[groupConfig] || nulloEditor;
  const EditForm = editor.editForm;
  const AddForm = editor.addForm;

  const createDeletionModal = (deleteHandler, flag) => (
    <YesNoModal
      isOpen={flag}
      title="Delete Group?"
      onYes={editor.deleteHandler}
      onNo={hideDeletionModal}
    />
  );

  const groupEditModal = () =>
    validId(groupIdBeingEdited) ? (
      <EditForm
        item={plantInventoryQueries.groupDetails[groupIdBeingEdited]}
        license={licenseToAddTo}
        saveHandler={editor.saveHandler}
        strains={strainQueries.strains}
        rooms={roomQueries.allRoomsForLicense(licenseToAddTo)}
        onClose={onModalClose}
        deleteHandler={promptForGroupDeletion}
      />
    ) : null;

  const groupAddModal = () =>
    showGroupAddModal ? (
      <AddForm
        item={editor.newItem(licenseToAddTo)}
        license={licenseToAddTo}
        strains={strainQueries.strains}
        rooms={roomQueries.allRoomsForLicense(licenseToAddTo)}
        saveHandler={editor.saveHandler}
        onClose={onModalClose}
      />
    ) : null;

  const groupDeletionModal = () =>
    createDeletionModal(editor.deleteHandler, showGroupDeleteModal);

  return (
    <div>
      <h1>Add Plant Inventory</h1>
      <WistiaVideo id={plantInventoryStep.intro.videoID} />

      {groupAddModal()}

      {groupEditModal()}

      {groupDeletionModal()}

      <InternalStepProgress
        nextStep={props.nextStep}
        stepsConfig={[
          {
            step: 1,
            label: 'Add Plant Inventory',
            component: (
              <LicenseWizardView
                licenses={licenses}
                {...props}
                {...plantInventoryQueries}
                {...roomQueries}
                {...strainQueries}
                skippableSectionsBuilders={skippableSectionsBuilders}
                groupTitleTemplate={groupTitleTemplate}
                onEditSeedGroup={editSeedGroup}
                onEditCloneGroup={editCloneGroup}
                onEditBulkFlowerGroup={editBulkFlowerGroup}
                onEditShakeTrimGroup={editShakeTrimGroup}
                onSaveSeedGroup={createNonModalSaveHandler(createSaveSeedGroupHandler)}
                onSaveCloneGroup={createNonModalSaveHandler(createSaveCloneGroupHandler)}
                onSaveBulkFlowerGroup={createNonModalSaveHandler(
                  createSaveBulkFlowerGroupHandler
                )}
                onSaveShakeTrimGroup={createNonModalSaveHandler(
                  createSaveShakeTrimGroupHandler
                )}
              />
            ),
          },
          {
            step: 2,
            label: 'Confirm Plant Inventory',
            component: (
              <ListingView
                stepCompleted={stepCompleted}
                licenses={licenses}
                {...props}
                {...plantInventoryQueries}
                {...roomQueries}
                {...strainQueries}
                skippableSectionsBuilders={skippableSectionsBuilders}
                groupTitleTemplate={groupTitleTemplate}
                onEditSeedGroup={editSeedGroup}
                onEditCloneGroup={editCloneGroup}
                onEditBulkFlowerGroup={editBulkFlowerGroup}
                onEditShakeTrimGroup={editShakeTrimGroup}
                onAddSeedGroup={addSeedGroup}
                onAddCloneGroup={addCloneGroup}
                onAddBulkFlowerGroup={addBulkFlowerGroup}
                onAddShakeTrimGroup={addShakeTrimGroup}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
