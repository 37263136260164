import { Button, Message } from 'semantic-ui-react';
import React, { useState } from 'react';
import { LicenseDetailsView } from './_LicenseDetailsView';

export const LicenseWizardView = props => {
  const { licenses } = props;
  const [itemIndex, setItemIndex] = useState(0);

  const previousLicense = () => setItemIndex(itemIndex - 1);
  const nextLicense = () => setItemIndex(itemIndex + 1);

  const actionsSection = () => (
    <>
      {itemIndex > 0 && (
        <Button onClick={previousLicense}>Back To Previous License</Button>
      )}
      {itemIndex < licenses.length - 1 && (
        <Button onClick={nextLicense}>Continue To Next License</Button>
      )}
      <Button onClick={props.nextInternalStep}>All Groups Are Setup</Button>
    </>
  );

  const noRoomsForLicenseSection = license =>
    props.allPlantRoomsForLicense(license).length === 0 ? (
      <Message header="You need to setup rooms for this license before you can add plant inventory" />
    ) : null;

  const licenseSection = license => (
    <LicenseDetailsView
      {...props}
      license={license}
      seedGroups={props.seedGroupsForLicense(license)}
      skippableSectionsBuilders={props.skippableSectionsBuilders(license)}
      cloneGroups={props.cloneGroupsForLicense(license)}
      bulkFlowerGroups={props.bulkFlowerGroupsForLicense(license)}
      shakeTrimGroups={props.shakeTrimGroupsForLicense(license)}
      rooms={props.allRoomsForLicense(license)}
    />
  );

  return (
    <>
      {props.noRoomsForLicense(licenses[itemIndex])
        ? noRoomsForLicenseSection(licenses[itemIndex])
        : licenseSection(licenses[itemIndex])}
      <div>{actionsSection()}</div>
    </>
  );
};
